import * as coreClient from "@azure/core-client";
import * as Parameters from "./models/parameters";
import * as Mappers from "./models/mappers";
import {
  SearchAPIOptionalParams,
  AzureSearchOptionalParams,
  AzureSearchResponse,
  AzureAutocompleteOptionalParams,
  AzureAutocompleteResponse,
  IndexerRequest,
  RecreateAzureSearchIndexOptionalParams,
} from "./models";

export class SearchAPI extends coreClient.ServiceClient {
  $host: string;

  /**
   * Initializes a new instance of the SearchAPI class.
   * @param $host server parameter
   * @param options The parameter options
   */
  constructor($host: string, options?: SearchAPIOptionalParams) {
    if ($host === undefined) {
      throw new Error("'$host' cannot be null");
    }

    // Initializing default values for options
    if (!options) {
      options = {};
    }
    const defaults: SearchAPIOptionalParams = {
      requestContentType: "application/json; charset=utf-8",
    };

    const packageDetails = `azsdk-js-navigation-rest-client/1.0.0-beta.1`;
    const userAgentPrefix =
      options.userAgentOptions && options.userAgentOptions.userAgentPrefix
        ? `${options.userAgentOptions.userAgentPrefix} ${packageDetails}`
        : `${packageDetails}`;

    const optionsWithDefaults = {
      ...defaults,
      ...options,
      userAgentOptions: {
        userAgentPrefix,
      },
      endpoint: options.endpoint ?? options.baseUri ?? "{$host}",
    };
    super(optionsWithDefaults);
    // Parameter assignments
    this.$host = $host;
  }

  /**
   * Transforms a generic search request into an Azure Cognitive Search request, performs the search and
   * transforms the Azure Cogintive Search result into a generic search result.
   *
   * @param genericSearchRequestJson serialized GenericSearchRequest object
   * @param options The options parameters.
   */
  azureSearch(
    genericSearchRequestJson: string,
    options?: AzureSearchOptionalParams,
  ): Promise<AzureSearchResponse> {
    return this.sendOperationRequest(
      { genericSearchRequestJson, options },
      azureSearchOperationSpec,
    );
  }

  /**
   * Transforms a generic autocomplete request into an Azure Cognitive Search request, performs the
   * search and transforms the Azure Cogintive Search result into a generic autocomplete result.
   *
   * @param genericAutocompleteRequestJson serialized GenericAutocompleteRequest object
   * @param options The options parameters.
   */
  azureAutocomplete(
    genericAutocompleteRequestJson: string,
    options?: AzureAutocompleteOptionalParams,
  ): Promise<AzureAutocompleteResponse> {
    return this.sendOperationRequest(
      { genericAutocompleteRequestJson, options },
      azureAutocompleteOperationSpec,
    );
  }

  /**
   * Drops a search index with the provided name and recreates it using the latest SearchDocument
   * definition.
   *
   * @param body
   * @param options The options parameters.
   */
  recreateAzureSearchIndex(
    body: IndexerRequest,
    options?: RecreateAzureSearchIndexOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { body, options },
      recreateAzureSearchIndexOperationSpec,
    );
  }
}
// Operation Specifications
const serializer = coreClient.createSerializer(Mappers, /* isXml */ false);

const azureSearchOperationSpec: coreClient.OperationSpec = {
  path: "/search",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GenericSearchResponse,
    },
    400: {},
    500: {},
  },
  queryParameters: [Parameters.genericSearchRequestJson],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.ocpApimSubscriptionKey],
  serializer,
};
const azureAutocompleteOperationSpec: coreClient.OperationSpec = {
  path: "/autocomplete",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GenericAutocompleteResponse,
    },
    400: {},
    500: {},
  },
  queryParameters: [Parameters.genericAutocompleteRequestJson],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept, Parameters.ocpApimSubscriptionKey],
  serializer,
};
const recreateAzureSearchIndexOperationSpec: coreClient.OperationSpec = {
  path: "/recreate",
  httpMethod: "POST",
  responses: { 200: {}, 500: {} },
  requestBody: Parameters.body,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.ocpApimSubscriptionKey, Parameters.contentType],
  mediaType: "json",
  serializer,
};
