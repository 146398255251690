import Vue from "vue";
import i18n from "./i18n";
import App from "./App.vue";
import VueSanitize from "vue-sanitize";

const siteSearch = document.getElementById('sm-site-search');

const defaultOptions = {
  allowedTags: ["strong"]
};

Vue.use(VueSanitize, defaultOptions);
Vue.config.productionTip = false;
Vue.config.devtools = false;

const defaultshowkeywords = true;
const defaultfilteryear = true;
const defaultfiltergroupids = "";
const defaultparentid = -1;
const defaultpagesize = 20;

const resourceindexelement = document.getElementById("sm-site-search");

const showkeywords =
  resourceindexelement?.getAttribute("data-showkeywords") == null
    ? defaultshowkeywords
    : resourceindexelement.getAttribute("data-showkeywords")?.toLowerCase() ===
      "true";
const filteryear =
  resourceindexelement?.getAttribute("data-filteryear") == null
    ? defaultfilteryear
    : resourceindexelement.getAttribute("data-filteryear")?.toLowerCase() ===
      "true";
const filtergroupids =
  resourceindexelement?.getAttribute("data-filtergroupids") ??
  defaultfiltergroupids;

let parentid = defaultparentid;
const castparentid = Number(
  resourceindexelement?.getAttribute("data-parentid")
);
if (!isNaN(castparentid) && castparentid !== 0) parentid = castparentid;

let pagesize = defaultpagesize;
const castpagesize = Number(
  resourceindexelement?.getAttribute("data-pagesize")
);
if (!isNaN(castpagesize) && castpagesize !== 0) pagesize = castpagesize;

const title = resourceindexelement?.getAttribute("data-title");
const key = resourceindexelement?.getAttribute("data-ocpApimSubscriptionKey");
const domain = resourceindexelement?.getAttribute("data-apidomain");

const index = resourceindexelement?.getAttribute("data-searchindex");

if (siteSearch) {
  new Vue({
    i18n,
    render: (h) =>
      h(App, {
        props: {
          showkeywords: showkeywords,
          filtergroupids: filtergroupids,
          filteryear: filteryear,
          ocpApimSubscriptionKey: key,
          parentid: parentid,
          pagesize: pagesize,
          title: title,
          apidomain: domain,
          searchIndex: index
        }
      })
  }).$mount("#sm-site-search");
}