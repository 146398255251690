<template>
  <a :href="result.url" class="resultItem" :aria-describedby="result.id">
    <h3 class="h5" :id="result.id">{{ result.title }}</h3>
    <p><span v-html="highlights"></span></p>
    <ul class="resultTags">
      <li v-for="tag in result.taxonomy" :key="tag.tag.id">
        {{ tag.tag.title }}
      </li>
    </ul>
  </a>
</template>
<script lang="ts">
import Vue from "vue";
//import { Resource } from "../models/Resource";
import { SearchResponseDocument  } from '@/client/src/models';
import { formatISODateMMDDYYYY } from "@/common/date";
export default Vue.extend({
  name: "ResultComponent",
  props: {
    result: {
      type: Object as () => SearchResponseDocument,
      required: true,
      default: () => ({})
    }
  },
  computed: {
    highlights() {
      if (this.result.highlights.description != null) {
        return this.$sanitize(this.result.highlights.description[0]);
      } else if (this.result.highlights.content != null) {
        return this.$sanitize(this.result.highlights.content[0]);
      } else if (this.result.highlights.nestedContent != null && this.result.highlights.nestedContent.length > 0) {
        return this.$sanitize(this.result.highlights.nestedContent[0]);
      }
      return this.result.content;
    }
  },
  methods: {
    formatISODate(input: string) {
      return formatISODateMMDDYYYY(input);
    }
  }
});
</script>
