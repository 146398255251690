<script lang="ts">
import Vue from "vue";
import { SearchAPI } from "../client/src/searchAPI";
import { GenericSearchRequestTaxonomyItem, GenericSearchRequest, AzureSearchResponse } from "../client/src/models";

export default Vue.extend({
  name: "RenderResources",
  mounted() {
    this.fetchResources(
      this.parentid,
      this.keyword,
      this.tags,
      this.years,
      this.page,
      this.pagesize,
      this.sortorder
    );
  },
  watch: {
    tags: function (
      newVal: GenericSearchRequestTaxonomyItem[],
      oldVal: GenericSearchRequestTaxonomyItem[]
    ) {
      this.fetchResources(
        this.parentid,
        this.keyword,
        newVal,
        this.years,
        this.page,
        this.pagesize,
        this.sortorder
      );
    },
    years: function (newVal: number[], oldVal: number[]) {
      this.fetchResources(
        this.parentid,
        this.keyword,
        this.tags,
        newVal,
        this.page,
        this.pagesize,
        this.sortorder
      );
    },
    keyword: function (newVal: string, oldVal: string) {
      this.fetchResources(
        this.parentid,
        newVal,
        this.tags,
        this.years,
        this.page,
        this.pagesize,
        this.sortorder
      );
    },
    page: function (newVal: number, oldVal: number) {
      this.fetchResources(
        this.parentid,
        this.keyword,
        this.tags,
        this.years,
        newVal,
        this.pagesize,
        this.sortorder
      );
    },
    sortorder: function (newVal: string[], oldVal: string[]) {
      this.fetchResources(
        this.parentid,
        this.keyword,
        this.tags,
        this.years,
        this.page,
        this.pagesize,
        newVal
      );
    },
  },
  props: {
    indexName: {
      type: String,
      required: true,
      default: "",
    },
    endPoint: {
      type: String,
      required: true,
      default: "",
    },
    parentid: {
      type: Number,
      required: true,
      default: -1,
    },
    tags: {
      type: Array as () => GenericSearchRequestTaxonomyItem[],
      required: false,
      default: null,
    },
    years: {
      type: Array as () => number[],
      required: false,
      default: () => [],
    },
    keyword: {
      type: String,
      required: false,
      default: "",
    },
    ocpApimSubscriptionKey: {
      type: String,
      required: false,
      default: "",
    },
    page: {
      type: Number,
      required: false,
      default: 0,
    },
    pagesize: {
      type: Number,
      required: false,
      default: 20,
    },
    sortorder: {
      type: Array as () => string[],
      required: false,
      default: () => [],
    },
    customHeaders: {
      type: Object as () => { [key: string]: string },
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      result: {} as AzureSearchResponse,
      loading: false as boolean,
      error: false as boolean,
    };
  },
  methods: {
    async fetchResources(
      parentid = -1,
      keyword = "",
      tags: GenericSearchRequestTaxonomyItem[] = [],
      years: number[] = [],
      page = 1,
      pagesize = 20,
      sortorder: string[] = []
    ) {
      this.$data.loading = true;
      this.$data.error = false;
      try {
        const skip = (page - 1) * pagesize;
        const genericSearchRequest: GenericSearchRequest = {
          index: this.$props.indexName,
          query: keyword,
          parents: [parentid.toString()],
          skip: skip,
          take: pagesize,
          sort: sortorder,
          yearsCreated: years,
          taxonomy: tags,
        };

        const SearchRepository = new SearchAPI(this.$props.endPoint);
        this.$data.result = await SearchRepository.azureSearch(
          JSON.stringify(genericSearchRequest),
          {
            ocpApimSubscriptionKey: this.ocpApimSubscriptionKey
          }
        );
        this.$data.loading = false;
        this.$emit("change", this.$data.result);
      } catch (e) {
        console.log(e);
        this.$data.error = true;
      }
    },
  },
  render() {
    return this.$scopedSlots.default!({
      result: this.$data.result,
      loading: this.$data.loading,
      error: this.$data.error,
    }) as any;
  },
});
</script>