import {
  OperationParameter,
  OperationURLParameter,
  OperationQueryParameter,
} from "@azure/core-client";
import { IndexerRequest as IndexerRequestMapper } from "../models/mappers";

export const accept: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String",
    },
  },
};

export const $host: OperationURLParameter = {
  parameterPath: "$host",
  mapper: {
    serializedName: "$host",
    required: true,
    type: {
      name: "String",
    },
  },
  skipEncoding: true,
};

export const genericSearchRequestJson: OperationQueryParameter = {
  parameterPath: "genericSearchRequestJson",
  mapper: {
    serializedName: "genericSearchRequestJson",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const ocpApimSubscriptionKey: OperationParameter = {
  parameterPath: ["options", "ocpApimSubscriptionKey"],
  mapper: {
    serializedName: "Ocp-Apim-Subscription-Key",
    type: {
      name: "String",
    },
  },
};

export const genericAutocompleteRequestJson: OperationQueryParameter = {
  parameterPath: "genericAutocompleteRequestJson",
  mapper: {
    serializedName: "genericAutocompleteRequestJson",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const contentType: OperationParameter = {
  parameterPath: ["options", "contentType"],
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Content-Type",
    type: {
      name: "String",
    },
  },
};

export const body: OperationParameter = {
  parameterPath: "body",
  mapper: IndexerRequestMapper,
};
