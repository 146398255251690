export const formatISODateMMDDYYYY = (input: string) => {
  const date = new Date(input);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date
    .getFullYear()
    .toString()
    .padStart(4, "0");
  const day = date
    .getDate()
    .toString()
    .padStart(2, "0");
  return `${day}/${month}/${year}`;
};
