`<template>
  <div id="app">
    <section class="searchBanner">
      <div class="container">
        <h2 v-if="title">{{ title }}</h2>
        <RenderResources
          :indexName="searchIndex"
          :endPoint="apidomain"
          :filteryear="filteryear"
          :ocpApimSubscriptionKey="ocpApimSubscriptionKey"
          :customHeaders="customHeaders"
          :parentid="parentid"
          :page="1"
          :filtersRequired="1"
        >
          <template slot-scope="{ loading, error }">
            <div class="formContainer">
              <div class="error" v-if="error">
                {{ $t("LoadingError") }}
              </div>
              <div class="loading" v-if="loading && !error">
                <p>{{ $t("LoadingFilters") }}</p>
              </div>
              <form @submit.prevent="updateKeyword" v-if="!loading && !error">
                <div class="searchInput">
                  <label for="searchBannerInput" class="sr-only"
                    >S{{ $t("SearchLabel") }}</label
                  >
                  <input
                    id="searchBannerInput"
                    @keydown.enter.prevent="updateKeyword"
                    type="text"
                    placeholder="Search by keyword"
                    class="sortingSearchInput"
                    v-model="keyword"
                  />
                  <button
                    class="clearButton"
                    v-if="keyword.length > 0"
                    @click="clearKeyword"
                    aria-label="Clear keyword"
                  >
                    <svg
                      id="close"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 14 13"
                      preserveAspectRatio="xMinYMid"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <g
                        id="Projects"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="HymansRobertson.Desktop.Project.Index.1a"
                          transform="translate(-706.000000, -394.000000)"
                          fill="#000000"
                        >
                          <path
                            d="M714,392.686292 L714,399.685292 L721,399.686292 L721,401.686292 L714,401.686292 L714,408.686292 L712,408.686292 L712,401.686292 L705,401.686292 L705,399.686292 L712,399.686292 L712,392.686292 L714,392.686292 Z"
                            id="icon/close"
                            transform="translate(713.000000, 400.686292) rotate(45.000000) translate(-713.000000, -400.686292) "
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </button>
                  <button
                    type="submit"
                    class="searchButton"
                    @click="updateKeyword"
                    aria-label="Submit search"
                  >
                    <svg
                      id="siteSearchIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 18 18"
                      preserveAspectRatio="xMinYMid"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path
                        d="M17.875 16.46l-4-4.005c-.13-.132-.274-.25-.43-.35l-1-.69c2.057-2.549 2.076-6.184.046-8.754C10.461.091 6.924-.727 3.973.69 1.023 2.107-.554 5.382.178 8.575c.731 3.194 3.575 5.454 6.847 5.442 1.588 0 3.13-.54 4.37-1.532l.75 1.002c.089.129.19.25.3.36l4 4.005c.094.095.222.148.355.148.133 0 .261-.053.355-.148l.7-.7c.19-.189.199-.493.02-.692zm-10.85-4.445c-2.761 0-5-2.241-5-5.006s2.239-5.006 5-5.006 5 2.241 5 5.006c0 1.328-.527 2.6-1.464 3.54-.938.938-2.21 1.466-3.536 1.466z"
                      />
                    </svg>
                  </button>
                </div>
              </form>
            </div>
          </template>
        </RenderResources>
      </div>
    </section>
    <section class="searchResults">
      <div class="container">
        <RenderResources
          :parentid="parentid"
          :indexName="searchIndex"
          :endPoint="apidomain"
          :tags="[]"
          :ocpApimSubscriptionKey="ocpApimSubscriptionKey"
          :customHeaders="customHeaders"
          :years="selectedYears"
          :page="currentPage"
          :keyword="keywordInput"
          :pagesize="pagesize"
          :sortorder="[orderBy]"
          @change="resourcesChanged"
          v-if="keywordInput"
        >
          <template v-slot="{ result, loading, error }">
            <div class="inner">
              <div class="error" v-if="error">
                {{ $t("LoadingResourceError") }}
              </div>
              <div class="loading" v-if="loading && !error">
                <p>{{ $t("LoadingResults") }}</p>
              </div>
              <div class="resultsTop">
                <ResultsIndicator
                  v-if="result !== undefined && result.count > 0"
                  :first="firstPage"
                  :term="keywordInput"
                  :last="lastPage(result.count)"
                  :total="result.count"
                ></ResultsIndicator>
              </div>
              <div
                class="resultsContainer"
                v-if="
                  !loading &&
                  !error &&
                  result.documents !== undefined &&
                  result.documents.length > 0
                "
              >
                <Result
                  v-for="responseValueItem in result.documents"
                  :key="responseValueItem.id"
                  :result="responseValueItem"
                ></Result>
              </div>

              <div
                class="noResults"
                v-if="
                  !loading &&
                  !error &&
                  (result === undefined || result.count === 0)
                "
              >
                <p>{{ $t("NoResults") }}</p>
              </div>

              <div class="pagination" v-if="totalPages > 1 && keywordInput">
                <paginate
                  :page-count="totalPages"
                  :click-handler="paginationClick"
                  :prev-text="'Previous'"
                  :next-text="'Next'"
                  :container-class="'className'"
                ></paginate>
              </div>
            </div>
          </template>
        </RenderResources>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import Paginate from "vuejs-paginate";

import Vue from "vue";
import ResultsIndicator from "./components/ResultsIndicator.vue";
import Result from "./components/Result.vue";
import { TaxonomyTag, GenericSearchResponseFacetsCreatedItem, AzureSearchResponse } from "./client/src/models"
import vSelect from "vue-select";
import RenderResources from "./components/RenderResources.vue";

export default Vue.extend({
  name: "SearchIndex",
  props: {
    showkeywords: {
      type: Boolean,
      required: true,
      default: true
    },
    filtergroupids: {
      type: String,
      required: true,
      default: ""
    },
    ocpApimSubscriptionKey: {
      type: String,
      required: false,
      default: ""
    },
    filteryear: {
      type: Boolean,
      required: true,
      default: true
    },
    parentid: {
      type: Number,
      required: true,
      default: -1
    },
    pagesize: {
      type: Number,
      required: true,
      default: 20
    },
    title: {
      type: String,
      required: true,
      default: ""
    },
    apidomain: {
      type: String,
      required: false,
      default: ""
    },
    searchIndex: {
      type: String,
      required: false,
      default: ""
    },
  },
  data() {
    return {
      keyword: "" as string,
      keywordInput: "" as string,
      currentPage: 1 as number,
      sortOptions: ["A-Z", "Z-A"] as string[],
      customHeaders: { Accept: "application/json;v=1" } as Record<string, any>,
      sortOption: "A-Z" as string,
      selectedTags: [],
      selectedYears: [],
      orderBy: "created desc" as string,
      totalPages: Number,
      tags: [] as TaxonomyTag[],
      datetags: [] as GenericSearchResponseFacetsCreatedItem[],
    };
  },
  mounted() {
    this.$data.keyword = this.getQueryVariable("query");
    this.$data.keywordInput = this.getQueryVariable("query");
  },
  computed: {
    firstPage(): number {
      return (this.currentPage - 1) * this.pagesize + 1;
    },
  },
  methods: {
    getQueryVariable(variable) {
      const query = window.location.search.substring(1);
      const vars = query.split("&");
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return "";
    },
    lastPage(total: number): number {
      return this.currentPage * this.pagesize > total
        ? total
        : this.currentPage * this.pagesize;
    },
    calculateTotalPages(total: number): number {
      return Math.ceil(total / this.pagesize);
    },
    paginationClick(page: number) {
      const elmnt = document.getElementById("resources");
      if (elmnt) {
        elmnt.scrollIntoView();
      }

      this.$data.currentPage = page;
    },
    updateKeyword() {
      this.$data.keywordInput = this.$data.keyword;
    },
    clearKeyword() {
      this.$data.keywordInput = "";
      this.$data.keyword = "";
    },
    resourcesChanged(update: AzureSearchResponse) {
      const total = update.count !== undefined ? update.count : 0;
      this.$data.totalPages = this.calculateTotalPages(total);
    },
  },

  components: {
    ResultsIndicator,
    Result,
    Paginate,
    RenderResources,
  },
});
</script>
