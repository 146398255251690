import { render, staticRenderFns } from "./ResultsIndicator.vue?vue&type=template&id=4c5dc4c2"
import script from "./ResultsIndicator.vue?vue&type=script&lang=ts"
export * from "./ResultsIndicator.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports