import * as coreClient from "@azure/core-client";

export const GenericSearchResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GenericSearchResponse",
    modelProperties: {
      count: {
        serializedName: "count",
        type: {
          name: "Number",
        },
      },
      facets: {
        serializedName: "facets",
        type: {
          name: "Composite",
          className: "GenericSearchResponseFacets",
        },
      },
      documents: {
        serializedName: "documents",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SearchResponseDocument",
            },
          },
        },
      },
    },
  },
};

export const GenericSearchResponseFacets: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GenericSearchResponseFacets",
    modelProperties: {
      taxonomy: {
        serializedName: "taxonomy",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TaxonomyCategory",
            },
          },
        },
      },
      created: {
        serializedName: "created",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "GenericSearchResponseFacetsCreatedItem",
            },
          },
        },
      },
    },
  },
};

export const TaxonomyCategory: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TaxonomyCategory",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String",
        },
      },
      id: {
        serializedName: "id",
        type: {
          name: "String",
        },
      },
      tags: {
        serializedName: "tags",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TaxonomyTag",
            },
          },
        },
      },
    },
  },
};

export const TaxonomyTag: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TaxonomyTag",
    modelProperties: {
      title: {
        serializedName: "title",
        required: true,
        type: {
          name: "String",
        },
      },
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      categoryId: {
        serializedName: "categoryId",
        required: true,
        type: {
          name: "String",
        },
      },
      count: {
        serializedName: "count",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const GenericSearchResponseFacetsCreatedItem: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className: "GenericSearchResponseFacetsCreatedItem",
      modelProperties: {
        count: {
          serializedName: "count",
          type: {
            name: "Number",
          },
        },
        value: {
          serializedName: "value",
          type: {
            name: "DateTime",
          },
        },
      },
    },
  };

export const SearchResponseDocument: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SearchResponseDocument",
    modelProperties: {
      score: {
        serializedName: "score",
        type: {
          name: "Number",
        },
      },
      highlights: {
        serializedName: "highlights",
        type: {
          name: "Composite",
          className: "SearchResponseDocumentHighlights",
        },
      },
      id: {
        serializedName: "id",
        type: {
          name: "String",
        },
      },
      title: {
        serializedName: "title",
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        type: {
          name: "String",
        },
      },
      content: {
        serializedName: "content",
        type: {
          name: "String",
        },
      },
      url: {
        serializedName: "url",
        type: {
          name: "String",
        },
      },
      created: {
        serializedName: "created",
        type: {
          name: "DateTime",
        },
      },
      images: {
        serializedName: "images",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Image",
            },
          },
        },
      },
      readTime: {
        serializedName: "readTime",
        type: {
          name: "Number",
        },
      },
      taxonomy: {
        serializedName: "taxonomy",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Taxonomy",
            },
          },
        },
      },
      linkedResources: {
        serializedName: "linkedResources",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Resource",
            },
          },
        },
      },
      customProperties: {
        serializedName: "customProperties",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "KeyValuePair",
            },
          },
        },
      },
    },
  },
};

export const SearchResponseDocumentHighlights: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SearchResponseDocumentHighlights",
    modelProperties: {
      description: {
        serializedName: "description",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      content: {
        serializedName: "content",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      nestedContent: {
        serializedName: "nestedContent",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
    },
  },
};

export const Image: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Image",
    modelProperties: {
      title: {
        serializedName: "title",
        required: true,
        type: {
          name: "String",
        },
      },
      url: {
        serializedName: "url",
        required: true,
        type: {
          name: "String",
        },
      },
      altText: {
        serializedName: "altText",
        required: true,
        type: {
          name: "String",
        },
      },
      rank: {
        serializedName: "rank",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const Taxonomy: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Taxonomy",
    modelProperties: {
      tag: {
        serializedName: "tag",
        type: {
          name: "Composite",
          className: "Tag",
        },
      },
      category: {
        serializedName: "category",
        type: {
          name: "Composite",
          className: "Category",
        },
      },
    },
  },
};

export const Tag: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Tag",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      title: {
        serializedName: "title",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Category: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Category",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      title: {
        serializedName: "title",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Resource: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Resource",
    modelProperties: {
      title: {
        serializedName: "title",
        required: true,
        type: {
          name: "String",
        },
      },
      type: {
        serializedName: "type",
        required: true,
        type: {
          name: "String",
        },
      },
      size: {
        serializedName: "size",
        required: true,
        type: {
          name: "Number",
        },
      },
      url: {
        serializedName: "url",
        required: true,
        type: {
          name: "String",
        },
      },
      rank: {
        serializedName: "rank",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const KeyValuePair: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "KeyValuePair",
    modelProperties: {
      key: {
        serializedName: "key",
        required: true,
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const GenericAutocompleteResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GenericAutocompleteResponse",
    modelProperties: {
      suggestions: {
        serializedName: "suggestions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
    },
  },
};

export const IndexerRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IndexerRequest",
    modelProperties: {
      indexName: {
        serializedName: "indexName",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const GenericSearchRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GenericSearchRequest",
    modelProperties: {
      index: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "index",
        required: true,
        type: {
          name: "String",
        },
      },
      query: {
        serializedName: "query",
        type: {
          name: "String",
        },
      },
      skip: {
        constraints: {
          InclusiveMinimum: 0,
        },
        serializedName: "skip",
        type: {
          name: "Number",
        },
      },
      take: {
        constraints: {
          InclusiveMaximum: 100,
          InclusiveMinimum: 0,
        },
        serializedName: "take",
        type: {
          name: "Number",
        },
      },
      parents: {
        serializedName: "parents",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      taxonomy: {
        serializedName: "taxonomy",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "GenericSearchRequestTaxonomyItem",
            },
          },
        },
      },
      yearsCreated: {
        serializedName: "yearsCreated",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Number",
            },
          },
        },
      },
      sort: {
        serializedName: "sort",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      highlightPreTag: {
        serializedName: "highlightPreTag",
        type: {
          name: "String",
        },
      },
      highlightPostTag: {
        serializedName: "highlightPostTag",
        type: {
          name: "String",
        },
      },
      takeFacets: {
        constraints: {
          InclusiveMaximum: 100,
          InclusiveMinimum: 0,
        },
        serializedName: "takeFacets",
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const GenericSearchRequestTaxonomyItem: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GenericSearchRequestTaxonomyItem",
    modelProperties: {
      tagId: {
        serializedName: "tagId",
        required: true,
        type: {
          name: "String",
        },
      },
      categoryId: {
        serializedName: "categoryId",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const GenericAutocompleteRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GenericAutocompleteRequest",
    modelProperties: {
      index: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "index",
        required: true,
        type: {
          name: "String",
        },
      },
      query: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "query",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const SearchDocument: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SearchDocument",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
      title: {
        serializedName: "title",
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        type: {
          name: "String",
        },
      },
      content: {
        serializedName: "content",
        type: {
          name: "String",
        },
      },
      nestedContent: {
        serializedName: "nestedContent",
        type: {
          name: "String",
        },
      },
      url: {
        serializedName: "url",
        type: {
          name: "String",
        },
      },
      created: {
        serializedName: "created",
        type: {
          name: "DateTime",
        },
      },
      images: {
        serializedName: "images",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Image",
            },
          },
        },
      },
      readTime: {
        serializedName: "readTime",
        type: {
          name: "Number",
        },
      },
      taxonomy: {
        serializedName: "taxonomy",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Taxonomy",
            },
          },
        },
      },
      linkedResources: {
        serializedName: "linkedResources",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Resource",
            },
          },
        },
      },
      parents: {
        serializedName: "parents",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      customProperties: {
        serializedName: "customProperties",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "KeyValuePair",
            },
          },
        },
      },
    },
  },
};

export const IndexerUpsertRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IndexerUpsertRequest",
    modelProperties: {
      ...IndexerRequest.type.modelProperties,
      searchDocument: {
        serializedName: "searchDocument",
        type: {
          name: "Composite",
          className: "SearchDocument",
        },
      },
    },
  },
};

export const IndexerDeleteRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IndexerDeleteRequest",
    modelProperties: {
      ...IndexerRequest.type.modelProperties,
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};
